@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.login {
  align-items: center;
  margin-top: 0;

  @media only screen and (min-width: $breakingPointMd) and (min-height: pxToRem(750)) {
    margin-top: pxToRem(-64);
  }
}

.logo {
  display: none;
  height: pxToRem(26);
  margin-bottom: 2rem;

  @media only screen and (min-width: $breakingPointMd) {
    display: block;
  }
}

.title,
.info {
  margin-bottom: 2rem !important;
}

.title {
  font-weight: $fontWeightRegular;
}

.forgotPassword {
  color: $brandPrimary;
  cursor: pointer;
  float: right;
  font-size: pxToRem(12);
  margin-top: -1rem;
  position: relative;
  z-index: 1;

  &:hover {
    color: $brandSecondary;
    text-decoration: none;
  }

  &:active,
  &:visited {
    color: $brandPrimary;
  }
}

.submitButton {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.termsAndConditions,
.candidatePortalLink {
  font-size: pxToRem(14);
  line-height: 1;
}
