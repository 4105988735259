@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.topLinksWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 25px;

  @media only screen and (min-width: $breakingPointSm) {
    flex-direction: row;
    align-items: center;
  }
}

.linkBack {
  display: inline-flex;
  color: $grayPrimary;
  cursor: pointer;
  padding-bottom: 10px;
  font-size: pxToRem(14);

  @media only screen and (min-width: $breakingPointSm) {
    padding-right: 15px;
    padding-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }

  & > svg {
    fill: $grayPrimary;
  }
}

.disabled {
  cursor: not-allowed;
  color: $eggshellSecondary;

  & > svg {
    fill: $eggshellSecondary;
  }
}

.jobTitle {
  font-size: pxToRem(14);
  color: $eggshellSecondary;
  padding-left: 15px;

  @media only screen and (min-width: $breakingPointSm) {
    border-left: 1px solid $eggshellSecondary;
  }
}

.candidateCard {
  border-radius: 16px;
  box-shadow: 0px 0px 10px #00000017;
  margin-bottom: 40px;
}
