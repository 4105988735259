@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.candidateActionsAndExtraInfo {
  padding: 30px;
  background-color: $white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
}

.rejectionReasonTitle,
.rejectionReasonContent,
.creditRefundedInfo,
.candidateHiredInfo,
.candidateContactedInfo,
.candidateContactedInfoDate,
.candidateInterestedInfo,
.candidateInterestedInfoText,
.candidateCallScheduledInfo {
  font-size: pxToRem(14);
  line-height: 22px;
}

.rejectionReasonTitle,
.creditRefundedInfo,
.candidateHiredInfo,
.candidateContactedInfo,
.candidateInterestedInfo,
.candidateCallScheduledInfo {
  font-weight: 500;
  margin-bottom: 0;
}

.rejectionReasonContent {
  margin-bottom: 0;
}

.rejectionReasonItem:not(:last-child) {
  margin-bottom: 20px;
}

.refundCreditSection {
  margin-top: 14px;
  position: relative;
}

.creditRefundedInfo {
  height: 42px;
  display: inline-flex;
  align-items: center;

  & > svg {
    fill: $eggshellSecondary;
  }
}

.candidateHiredInfo {
  color: $greenPrimary;

  & > svg {
    fill: $greenPrimary;
  }
}

.candidateContactedInfoDate,
.candidateInterestedInfoText {
  margin: 0;
}

.interestedActions {
  align-items: flex-end;
}

.candidateInfoDivider {
  margin: 25px 0;
  border-top: 1px solid $eggshellPrimary;
}

.interestedActionsItemDivider {
  display: none;
  width: 1px;
  height: pxToRem(42);
  background-color: $eggshellSecondary;
  margin: 0 15px;

  @media only screen and (min-width: $breakingPointXl) {
    display: block;
  }
}

.candidateFeedbackLabel {
  @media (max-width: 576px) {
    padding-top: 15px;
  }
}

.notInterestedReason {
  padding-top: 14px;
}

.nothingToShowBase {
  color: $eggshellSecondary;
  font-size: pxToRem(14);
}

.internalInformation {
  position: absolute;
  right: 30px;
  font-size: 14px;

  .internalInformationItem:not(:last-child) {
    margin-right: 14px;
  }

  .internalInformationItemInfoPlus {
    fill: $brandPrimary;
    margin-right: 8px;
    margin-top: -2px;
  }

  .internalInformationItemLabel {
    font-weight: $fontWeightBold;
    color: $brandPrimary;
    margin-right: 3px;
  }

  .internalInformationItemIcon {
    fill: $brandPrimary;
  }
}

.candidateATS {
  font-size: pxToRem(14);

  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.candidateATSItemLabel {
  font-weight: $fontWeightMedium;
}

.candidateATSLabel {
  margin-bottom: 4px;
}

.candidateATSItem:not(:last-child),
.candidateATSItemLabel {
  margin-right: 4px;
}
