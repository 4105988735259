@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.allVersionsModal {
  @media only screen and (min-width: $breakingPointLg) {
    width: 950px !important;
  }

  @media only screen and (min-width: $breakingPointXl) {
    width: 1170px !important;
  }
}

.allVersionsModalBody {
  .versionBlock {
    padding-bottom: 60px;
  }

  .versionBlock:first-child {
    padding-top: 60px;
  }

  .versionTitle {
    padding-bottom: 12px;
    font-size: pxToRem(18);
    font-weight: $fontWeightMedium;
  }

  .versionDate {
    font-size: pxToRem(14);
    color: $eggshellSecondary;
  }

  .versionText {
    font-size: pxToRem(14);
    padding-top: 30px;
    line-height: pxToRem(22);
  }
}
