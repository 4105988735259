@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.placeholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text {
    text-align: center;
  }

  &.positionRight {
    flex-direction: row;

    .icon {
      margin-bottom: 0;
      margin-right: 25px;
    }

    .text {
      text-align: left;
    }
  }

  &.positionLeft {
    flex-direction: row-reverse;

    .icon {
      margin-bottom: 0;
      margin-left: 25px;
    }

    .text {
      text-align: left;
    }
  }

  &.positionTop {
    flex-direction: column-reverse;

    .icon {
      margin-bottom: 0;
      margin-top: 25px;
    }
  }

  .icon {
    align-items: center;
    background-color: $white;
    border-radius: pxToRem(12);
    border: 1px solid $eggshellPrimary;
    box-shadow: 0px 3px 8px #00000014;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding: 15px 15px;
    text-align: center;
  }

  .iconCircle,
  .iconLineOne,
  .iconLineTwo {
    background-color: $eggshellPrimary;
    display: inline-block;
  }

  .iconCircle {
    border-radius: 50%;
    height: pxToRem(32);
    width: pxToRem(32);
  }

  .iconLineOne {
    border-radius: pxToRem(3);
    margin: 11px 0 9px;
    height: pxToRem(6);
    width: pxToRem(64);
  }

  .iconLineTwo {
    border-radius: pxToRem(2.5);
    height: pxToRem(5);
    width: pxToRem(38);
  }

  .text {
    color: $eggshellSecondary;
    font-size: pxToRem(20);
    line-height: pxToRem(26);
  }
}
