@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

$topOffset: pxToRem(88);
$menuWidth: pxToRem(214);

.layout {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$topOffset});

  @media only screen and (min-width: $breakingPointLg) {
    align-items: stretch;
  }
}

// Side Menu
.sideMenu {
  background-color: $white;
  box-shadow: 0 pxToRem(5) pxToRem(15) #0000000f;
  display: block;
  display: flex;
  height: auto;
  padding: 20px 0 14px;
  position: fixed;
  transition: top 0.3s;
  width: 100%;
  z-index: 10;

  @media only screen and (min-width: $breakingPointSm) {
    padding: 20px 30px 14px;
  }

  @media only screen and (min-width: $breakingPointLg) {
    box-shadow: pxToRem(5) 0 pxToRem(15) #0000000f;
    height: 100vh;
    left: 0;
    padding: 32px 8px;
    top: $topOffset;
    width: $menuWidth;
  }
}

// Main Menu
.menuWrapper {
  width: 100%;
}

.menu {
  display: inline-flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media only screen and (min-width: $breakingPointSm) {
    justify-content: center;
  }

  @media only screen and (min-width: $breakingPointLg) {
    display: block;
    justify-content: unset;
  }
}

.menuItem {
  color: $grayPrimary;
  display: inline-block;
  font-size: pxToRem(14);
  line-height: pxToRem(22);
  margin: 0 8px;
  padding: 13px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  @media only screen and (min-width: $breakingPointLg) {
    display: block;
    margin: 0;
    padding: 16px 24px;
  }

  &:hover {
    .menuItemLink {
      color: $brandPrimary;
    }
  }
}

.menuItemActive {
  background-color: $eggshellLight;
  border-radius: pxToRem(6);

  .menuItemLink {
    color: $brandPrimary !important;
  }
}

.menuItemLink {
  align-items: center;
  color: $grayPrimary;
  cursor: pointer;
  display: flex;
  text-decoration: none;

  &:visited {
    color: $grayPrimary;
  }

  &:hover {
    text-decoration: none;
  }
}

// Content
.contentWrapper {
  padding-top: 96px;
  width: 100%;

  @media only screen and (min-width: $breakingPointLg) {
    padding-top: 0;
    padding-left: $menuWidth;
  }
}

.content {
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 16px;

  @media only screen and (min-width: $breakingPointLg) {
    padding: 0 32px;
  }
}
