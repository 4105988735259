@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.modal {
  .modalBody {
    margin: 10px 0 78px;
    text-align: center;

    .modalBodyText {
      font-size: pxToRem(14);
      line-height: pxToRem(22);
    }
  }
}
