@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import './candidateBody.module.scss';

.contentCard {
  @extend .contentCardBase;
  position: relative;
}

.section {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.sectionTitle {
  font-size: pxToRem(16);
  font-weight: $fontWeightMedium;
  line-height: pxToRem(22);
  padding-top: 30px;
}

.sectionContent {
  display: flex;
  flex-wrap: wrap;
}

.sectionItem,
.sectionItemNoData,
.downloadAll {
  margin-bottom: 30px;
}

.sectionItem {
  width: 100%;

  @media only screen and (min-width: $breakingPointSm) and (max-width: $breakingPointLg) {
    width: 50%;
    padding-right: 30px;

    &:nth-child(2n) {
      padding-right: 0;
    }
  }

  @media only screen and (min-width: $breakingPointLg) {
    padding-right: 30px;
    width: 33.33%;

    &:nth-child(3n) {
      padding-right: 0;
    }
  }
}

.sectionItemContent {
  -webkit-line-clamp: 2;
  // Addition lines for 2 line or multiline ellipsis
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: pxToRem(14);
  line-break: anywhere;
  line-height: pxToRem(18);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  &:hover {
    color: $brandPrimary;

    svg {
      color: $brandPrimary;
    }
  }
}

.sectionItemText {
  display: -webkit-box;
  max-height: calc(pxToRem(18) * 2);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sectionItemIcon {
  flex: 0 0 auto;
  margin-left: -8px;
  margin-right: 10px;
  color: $eggshellSecondary;
  width: 46px;
}

.sectionItemNoData {
  color: $eggshellSecondary;
  font-size: pxToRem(14);
}

.sectionItemNoDataRemindBtn {
  margin-top: 30px;
}
