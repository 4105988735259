@import '../../../styles/refactoring/variables';

.iconWrapper {
  .icon {
    fill: $grayPrimary;
    margin-left: 8px;

    &:hover {
      fill: $brandPrimary;
    }

    &:focus {
      outline: none;
    }
  }
}
