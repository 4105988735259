b,
strong {
  font-weight: 500;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Fix placeholder color
::-webkit-input-placeholder {
  opacity: 1;
}
