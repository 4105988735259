@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.purchasesCard {
  padding: 30px;
}

.purchasesTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px !important;
}

.fixedCell {
  white-space: pre-wrap;
  width: pxToRem(180);
  line-height: pxToRem(22);
}
