.calendarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.warningMessage {
  padding-bottom: 10px;
}

.modalFooterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 370px;
  width: 100%;
}

.confirmButton {
  margin-bottom: 27px;
}
