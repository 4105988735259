@import '../../../../../../styles/refactoring/variables';

.disclaimerContainer {
  background-color: $brandLight;
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
  padding: 8px;
}
