@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.modal {
  max-height: calc(100% - 100px);

  .modalHeader {
    margin-bottom: 58px;
  }

  .modalSubHeader {
    font-size: pxToRem(14);
    margin-top: 10px;
  }

  .modalBody {
    margin-bottom: 55px;
  }
}
