@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.totalCreditsCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

// Credits
.totalCreditsTextWrapper {
  display: flex;
  flex-direction: column;
  padding: 18px 30px 26px;
}

.totalCreditsNumber {
  font-size: pxToRem(32);
  font-weight: $fontWeightMedium;
  color: $greenPrimary;
}

.totalCreditsLabel {
  font-size: pxToRem(14);
}

// Plan
.planWrapper {
  padding: 18px 30px 30px;
  border-top: 1px solid $eggshellPrimary;
}

.planItem {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;

  &:nth-child(-n + 2) {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: $breakingPointMd) {
    &:nth-child(-n + 2) {
      margin-bottom: 0;
    }
  }
}

.activeJobsNumber {
  font-size: pxToRem(32);
  font-weight: $fontWeightMedium;
}

.activeJobsSubscriptionNumber {
  span:first-child {
    font-size: pxToRem(32);
    font-weight: $fontWeightMedium;
    color: $greenPrimary;
  }

  span:nth-child(2) {
    margin: 0 6px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    font-size: pxToRem(14);
    font-weight: $fontWeightMedium;
  }
}

.activeJobsLabel,
.activePlanLabel,
.activePlanValidityLabel {
  font-size: pxToRem(14);
  display: flex;
  align-items: center;
}

.activeJobsTooltipContent {
  max-width: 322px;
  display: inline-block;
}

.activeJobsTooltipIcon {
  margin: -4px 0 0 8px;

  &:focus {
    outline: none;
  }

  &:hover {
    fill: $brandPrimary;
  }
}

.activePlanName,
.activePlanValidityDate {
  font-weight: $fontWeightMedium;
  padding-bottom: 6px;
  font-size: pxToRem(14);
}

.planMessage {
  align-items: center;
  background-color: $eggshellLight;
  border-radius: 8px;
  display: flex;
  font-size: pxToRem(14);
  line-height: pxToRem(22);
  margin-top: 30px;
  padding: 13px 18px 13px 14px;
}

.planMessageIcon {
  fill: $greenPrimary;
  margin-right: 14px;
  flex: 0 0 auto;
}
