// Colors
$grayPrimary: #213057;
$graySecondary: #192543;
$brandPrimary: #537bdf;
$brandSecondary: #4262b2;
$brandLight: #d4dbee;
$greenPrimary: #00977c;
$greenSecondary: #66c1b0;
$greenLight: #b3e0d8;
$eggshellPrimary: #e9e6dc;
$eggshellSecondary: #a6a39b;
$eggshellLight: #f8f8f4;
$orangePrimary: #eca613;
$orangeSecondary: #bd8510;
$orangeLight: #f5ca71;
$purplePrimary: #8e1c5a;
$purpleSecondary: #551136;
$purpleLight: #bb779c;
$redPrimary: #ea4858;
$redSecondary: #8c2b35;
$redLight: #ffc6cc;
$black: #000000;
$white: #ffffff;

// New Colors
$gray2: #e2e4e7;

// Border
$borderColor: $gray2;
$borderRadius: 0.125rem;

// Input
$inputLineHeight: 1.625rem;
$inputSmallFontSize: 0.875rem;
$inputSmallPaddingX: 0.675rem;
$inputSmallPaddingY: 0.375rem;
$inputNormalFontSize: 1rem;
$inputNormalPaddingX: 0.75rem;
$inputNormalPaddingY: 0.5rem;
$inputLargeFontSize: 1.125rem;
$inputLargePaddingX: 1.25rem;
$inputLargePaddingY: 0.625rem;

// Button
$buttonLineHeight: $inputLineHeight;
$buttonSmallFontSize: $inputSmallFontSize;
$buttonSmallPaddingX: 0.875rem;
$buttonSmallPaddingY: 0.375rem;
$buttonNormalFontSize: $inputNormalFontSize;
$buttonNormalPaddingX: 1.25rem;
$buttonNormalPaddingY: 0.5rem;
$buttonLargeFontSize: $inputLargeFontSize;
$buttonLargePaddingX: 1.75rem;
$buttonLargePaddingY: 0.625rem;

// Font
$fontFamily: 'Graphik' !default;
$fontWeightThin: 100;
$fontWeightExtralight: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;
$fontWeightBold: 700;
$fontWeightBlack: 800;
$fontWeightSuper: 900;

// Breaking points
$breakingPointSm: 576px;
$breakingPointMd: 768px;
$breakingPointLg: 992px;
$breakingPointXl: 1200px;
$breakingPointXxl: 1600px;

// Tag
$tagFontSize: 0.875rem;

// Card
$cardBorderColor: $gray2;
$cardBorderRadius: 1rem;
