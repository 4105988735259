@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.candidates {
  padding-top: 40px;
  padding-bottom: 80px;

  @media only screen and (min-width: $breakingPointLg) {
    padding-bottom: 40px;
  }
}

.candidatesTotalCount {
  background-color: $eggshellPrimary;
  border-radius: pxToRem(12);
  display: inline-block;
  font-size: pxToRem(12);
  font-weight: $fontWeightRegular;
  margin-left: 10px;
  padding: 5px 12px;
}

.tabLabel {
  &:hover {
    .tabLabelText,
    .tabLabelNumber {
      color: $brandPrimary;
    }
  }
}

.tabLabelActive {
  .tabLabelText,
  .tabLabelNumber {
    color: $brandPrimary;
  }
}

.tabLabelEmpty {
  .tabLabelText,
  .tabLabelNumber {
    color: $eggshellSecondary;
  }
}

.tabLabelText {
  color: $grayPrimary;
}

.tabLabelNumber {
  color: $eggshellSecondary;
  padding-left: 6px;
}

.tabLabelNumberSelected {
  color: $brandPrimary;
}

.pagination {
  position: fixed;
  left: 0;
  bottom: 0;

  border-radius: 0 !important;

  @media only screen and (min-width: $breakingPointLg) {
    position: relative;
    left: unset;
    bottom: unset;
    margin-top: 40px;
    border-radius: 0.5rem !important;
  }
}

.analytics {
  padding-top: 40px;
}

.placeholder {
  padding-top: 80px;
  padding-bottom: 80px;
}
