@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.register {
  align-items: center;
  margin-top: 0;

  @media only screen and (min-height: pxToRem(900)) {
    margin-top: -64px;
  }
}

.info {
  display: none;

  @media only screen and (min-width: $breakingPointMd) {
    display: block;
  }
}

.logo {
  height: pxToRem(26);
  margin-bottom: 48px;
}

.title {
  font-weight: $fontWeightRegular;
}

.infoList {
  margin-top: 48px;
  width: 75%;
}

.infoListItem {
  margin-bottom: 25px !important;
  padding-left: 30px;
  position: relative;
  line-height: 1;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    color: $greenPrimary;
    content: '•';
    font-size: 1.5rem;
    left: 0;
    line-height: 0.5;
    position: absolute;
    top: 0;
  }
}

.termsAndConditionsLabel {
  line-height: 1;
}
