@import '../../../../styles/refactoring/variables';

.barTitle {
  padding: 25px 25px 15px;
}

.barContainerLegend {
  padding: 0 25px 25px;

  .legendList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
  }

  .legendItem {
    font-size: 0.75rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .legendColor {
    border-radius: 50%;
    display: inline-block;
    flex: 0 0 auto;
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }

  .legendText {
    font-weight: $fontWeightRegular;
    flex: 0 1 100%;
  }
}

.barContainer {
  flex: 1 0 auto;
  padding: 0 25px 25px;

  .barContainerChart {
    height: 240px;

    @media only screen and (min-width: $breakingPointLg) {
      height: 260px;
    }
  }
}

.noDataLabel {
  font-size: 0.75rem;
  font-weight: $fontWeightRegular;
  margin-top: 10px;
}

.doughnutTitle {
  padding: 25px;
}

.doughnutContainer {
  display: flex;
  align-items: center;
  padding: 0 25px 25px;

  .doughnutContainerChart,
  .doughnutContainerLegend {
    flex: 0 0 50%;
    width: 50%;
  }

  .doughnutContainerChart {
    padding-right: 35px;
    height: 240px;
    display: flex;
    align-items: center;

    @media only screen and (min-width: $breakingPointLg) {
      height: 260px;
    }

    & > canvas {
      max-height: 100%;
      max-width: 100%;
      width: auto !important;
      height: auto !important;
    }
  }

  .doughnutContainerLegend {
    padding-left: 35px;

    .legendList {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .legendItem {
      font-size: 0.75rem;
      line-height: 1.125rem;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 17px;
      }
    }

    .legendColor {
      border-radius: 50%;
      display: inline-block;
      margin-top: 2px;
      flex: 0 0 auto;
      height: 16px;
      width: 16px;
    }

    .legendPercentage {
      flex: 0 0 35px;
      min-width: 35px;
      white-space: nowrap;
      font-weight: $fontWeightMedium;
      margin-left: 10px;
    }
  }
}
