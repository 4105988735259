@import '../../../../../../styles/refactoring/variables';

.customBenefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.customBenefitsAdd {
  margin: 30px 0;
}

// CUSTOM BENEFIT
.customBenefit {
  border-radius: 12px;
  border: 1px solid $eggshellPrimary;
  overflow: hidden;
  margin-bottom: 30px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.customBenefitHeader {
  display: flex;
  justify-content: space-between;
  background-color: $eggshellLight;
  border-bottom: 1px solid $eggshellPrimary;
  padding: 13px 30px;
}

.removeBenefitIcon {
  fill: $redPrimary !important;
  cursor: pointer;
}

.customBenefitBody {
  display: flex;
  padding: 30px;
}

.customBenefitSelect {
  margin-right: 30px;
  flex: 0 1 auto;
  width: 85px;
}

.customBenefitTextareas {
  flex: 1;
}

.customBenefitTextarea {
}

// CUSTOM BENEFIT - ICON SELECT
.iconsContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.iconSelected {
  border: 1px solid $eggshellPrimary;
  border-radius: 4px;
  height: 42px;
  display: flex;
  padding: 0 26px 0 8px;
  align-items: center;
  justify-content: center;
  position: relative;

  &.isOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  svg {
    height: 24px;
    width: auto;
  }
}

.iconSelectedArrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  fill: $eggshellSecondary !important;

  &.isOpen {
    transform: translateY(-50%) rotate(180deg);
  }
}

.iconOptionsWrapper {
  display: none;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid $eggshellPrimary;
  width: 100%;
  background-color: $white;
  max-height: 200px;
  overflow: auto;

  &.isOpen {
    border-top: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: inline-block;
  }
}

.iconOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  svg {
    margin: 10px auto;
  }
}

.iconOption {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $brandLight;
  }
}

.icon {
  width: 24px;
}

// CUSTOM BENEFIT - TEXAREAS
