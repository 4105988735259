@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

$cardBorderRadius: 1rem;

.candidateCard {
  background-color: $white;
  border-radius: $cardBorderRadius;
  border: 1px solid $eggshellPrimary;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: $eggshellSecondary;
  }

  &.notClickable {
    cursor: unset;

    &:hover {
      border-color: $eggshellPrimary;
    }
  }
}

.notPaidCard {
  position: relative;
  cursor: default;
}

.notPaidContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($grayPrimary, 0.9);
  color: $white;
  border-radius: $cardBorderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  backdrop-filter: blur(3px);
}

.cardHeader {
  background-color: $eggshellLight;
  border-top-left-radius: $cardBorderRadius;
  border-top-right-radius: $cardBorderRadius;
  padding: 14px 24px;
}

.candidate {
  display: flex;
  align-items: center;
  min-height: calc(1.375rem * 3);
}

.notPaidTitle {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  font-size: pxToRem(16);
  font-weight: $fontWeightMedium;
}

.notPaidDesc {
  font-size: pxToRem(14);
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
}

.candidateImageContainer {
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $eggshellPrimary;
  display: inline-flex;
  flex: 0 0 auto;
  height: 2.875rem;
  justify-content: center;
  margin-right: 12px;
  position: relative;
  width: 2.875rem;
}

.candidateImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.noElasticSearchIcon {
  right: -2px;
  bottom: -2px;
}

.fullNameAndPosition {
  display: -webkit-box;
  max-height: calc(1.375rem * 3);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fullName,
.position {
  color: $grayPrimary;
  font-weight: $fontWeightMedium;
}

.fullName {
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
}

.fullNameText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fullNameValidity {
  flex: 0 0 auto;
  margin-left: 8px;
}

.fullNameValidityValid {
  fill: $greenLight;

  &:hover {
    fill: $greenPrimary;
  }
}

.fullNameValidityAcceptable {
  fill: $orangeLight;

  &:hover {
    fill: $orangePrimary;
  }
}

.fullName + .position {
  font-size: 0.75rem;
  font-weight: $fontWeightRegular;
  line-height: 1.125;
}

.position {
  font-size: 1rem;
  line-height: 1.375rem;
}

.cardBody {
  padding: 16px 24px;
}

.locationAndLanguages {
  display: flex;
  justify-content: space-between;
  height: calc(1.125rem * 2);
  margin-bottom: 2px;
}

.location {
  display: flex;
  flex: 1;
}

.locationIcon {
  fill: $eggshellSecondary !important;
  margin-top: -2px;
  margin-left: -8px;
  flex: 0 0 auto;
}

.locationName {
  color: $eggshellSecondary;
  font-size: 0.75rem;
  line-height: 1.125rem;
  flex: 1;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-start;
  padding: 2px 0;
}

.languageFlag {
  margin-left: 10px;
  height: 0.875rem;
  width: 0.875rem;
  flex: 0 0 auto;
}

.additionalLanguages {
  background-color: $eggshellPrimary;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  color: $eggshellSecondary;
}

.info {
  display: flex;
}

.labels,
.values {
  max-width: 50%;
}

.labelItem,
.valueItem {
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.labelItem {
  color: $eggshellSecondary;
  margin-right: 20px;
}

.valueItem {
  color: $grayPrimary;
}
