@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import '../../../../../../styles/refactoring/mixins';

.validatedCandidates {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .accordionHeader {
    align-items: center;
    display: flex;
    padding: 20px 30px;
    background-color: $white;
  }

  .accordionBody {
    border-top: 1px solid $eggshellPrimary;
    padding: 0;
    position: relative;
  }
}

.divider {
  display: block;
  background-color: $eggshellPrimary;
  margin: 30px 0;
  height: 1px;
  width: 100%;
}
