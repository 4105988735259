@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.profileViewersCard {
  align-items: center;
  background-color: $grayPrimary;
  border-radius: pxToRem(16);
  display: flex;
  min-height: 210px;
  height: 100%;
  justify-content: center;
}

.content {
  color: $white;
  font-size: pxToRem(16);
  font-weight: $fontWeightMedium;
  line-height: pxToRem(24);
  max-width: 200px;
  text-align: center;
}
