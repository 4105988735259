@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.accordion {
  margin-bottom: 1.325rem;
}

.accordionItem {
  border: 0;
}

.accordionHeader {
  background: $white;
  color: $brandPrimary;
  font-size: pxToRem(14);
  line-height: pxToRem(15);
  display: flex;
  align-items: center;
  padding: 0 0 0.375rem 0;
}

.accordionHeaderArrow {
  margin-right: 5px;
  fill: $brandPrimary;
}

.accordionHeaderArrowExpanded {
  transform: rotate(90deg);
}

.accordionBody {
  padding: 0.375rem 1.875rem;
}

.accordionBodyItem {
  font-size: pxToRem(14);
  line-height: pxToRem(15);

  &:not(:last-child) {
    margin-right: 20px;
  }
}
