@import '../../../styles/refactoring/variables';

.noElasticSearchBadge {
  background-color: $orangePrimary;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.elasticSearchIcon {
  fill: $white !important;
  margin-top: -2px;
  width: 20px;
  height: 20px;
}

.tooltipText {
  display: block;
  text-align: center;
}
