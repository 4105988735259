.chargeCreditsCard {
  padding: 30px;
  margin-bottom: 30px;
}

.chargeCreditsCardTitle {
  align-items: center;
  display: flex;
  line-height: 1;
  margin-bottom: 20px !important;
}
