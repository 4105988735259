@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import '../../../../../../styles/refactoring/mixins';

.arrows {
  display: none;
  margin-left: auto;
  position: absolute;
  right: 20px;
  top: -50px;

  @media only screen and (min-width: $breakingPointSm) {
    display: block;
  }

  &.arrowsActive {
    svg:hover {
      cursor: pointer;
      fill: $brandPrimary;
    }
  }

  svg {
    cursor: not-allowed;
    fill: $eggshellSecondary;
  }
}

.arrowItem {
  display: inline-flex;
  background-color: $white;
  border-radius: pxToRem(5);
  padding: 4px;

  &:first-child {
    margin-right: 20px;
  }
}

.jobs {
  display: flex;
  overflow-x: scroll;
  width: 100%;

  // Hide scrollbar but keep scrolling
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.job {
  border-right: 1px solid $eggshellPrimary;
  display: flex;
  flex-direction: column;

  &:last-child {
    border-right: 0;
  }
}

.jobName {
  @include ellipsis;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  font-size: pxToRem(14);
  line-height: pxToRem(32);
  padding: 0 30px 16px;
}

.candidates {
  display: flex;
  padding: 24px 15px 16px;
}

.jobStatusIcon {
  margin-left: 8px;
}

.jobStatusIconCheckingInterest {
  svg {
    color: $brandPrimary;
  }
}

.jobStatusIconJobDescriptionDone {
  svg {
    fill: $greenPrimary;
  }
}

.jobStatusIconJobOnHold {
  svg {
    color: $eggshellSecondary;
  }
}

.jobStatusIconJobClosed {
  svg {
    fill: $redPrimary;
  }
}

.jobStatusIconSearchDone {
  svg {
    scale: 0.9;
    fill: $eggshellSecondary;
  }
}
