@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import '../../../../../../styles/refactoring/mixins';

.applyEllipis {
  @include ellipsis;

  width: 100%;
}

.candidateInfo {
  padding: 30px 30px 0 30px;
  background-color: $eggshellLight;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;

  @media only screen and (min-width: $breakingPointSm) {
    padding: 0 30px;
    position: static;
  }
}

.candidateInfoFirstPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;

  @media only screen and (min-width: $breakingPointSm) {
    flex-direction: row;
  }
}

.candidateImgContainer {
  width: pxToRem(106);
  height: pxToRem(106);
  position: relative;
}

.candidateImg {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.noElasticSearchIcon {
  right: 5px;
  bottom: 5px;
}

.candidateTextInfoWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  align-items: center;
  overflow: hidden;

  @media only screen and (min-width: $breakingPointSm) {
    align-items: flex-start;
    padding-left: 30px;
  }
}

.statusWrapper {
  position: absolute;
  top: 30px;
  left: 30px;

  @media only screen and (min-width: $breakingPointSm) {
    position: static;
    display: inline-flex;
  }
}

.candidateStatus {
  display: inline-flex;
  align-items: center;
  font-size: pxToRem(12);
  line-height: 1;
  border-radius: 12px;
  padding: 0 25px;
  height: 24px;

  & > svg {
    fill: $white;
  }
}

.anonymous {
  background-color: $eggshellSecondary;
  color: $white;
  margin-left: 20px;
}

.statusInterested {
  color: $greenPrimary;
  border: 1px solid $greenPrimary;
}

.statusInterestedAcceptable {
  color: $orangePrimary;
  border: 1px solid $orangePrimary;
}

.statusNotInterested {
  border: 1px solid $eggshellSecondary;
  color: $eggshellSecondary;
}

.statusHired {
  background-color: $greenPrimary;
  color: $white;
}

.statusRejected {
  color: $redPrimary;
  border: 1px solid $redPrimary;
}

.statusContacted {
  color: $brandPrimary;
  border: 1px solid $brandPrimary;
}

.statusCallScheduled {
  color: $purplePrimary;
  border: 1px solid $purplePrimary;
}

.candidateName,
.candidatePosition {
  color: $grayPrimary;
  font-weight: $fontWeightMedium;
}

.candidateName {
  @extend .applyEllipis;

  font-size: pxToRem(32);
  padding-top: 3px;
  text-align: center;
  white-space: pre-wrap;

  @media only screen and (min-width: $breakingPointSm) {
    text-align: left;
    white-space: nowrap;
  }
}

.candidateName + .candidatePosition,
.candidateIndustry {
  font-size: pxToRem(14);
  font-weight: $fontWeightRegular;
  padding-top: 0;
}

.candidatePosition:not(.candidateName + .candidatePosition) {
  @extend .applyEllipis;

  font-size: pxToRem(32);
  text-transform: capitalize;
  padding-top: 3px;
  text-align: center !important;
  white-space: pre-wrap !important;

  @media only screen and (min-width: $breakingPointSm) {
    text-align: left !important;
    white-space: nowrap !important;
  }
}

.candidateContactInfo {
  padding: 15px 0 15px 0;
  display: flex;
  flex-wrap: wrap;
  font-size: pxToRem(14);

  .candidateContactInfoItem {
    box-sizing: border-box;
    display: inline-flex;
    margin: 10px 0;
    padding: 0 10px;
    color: $graySecondary;
    width: 100%;

    &:first-child {
      padding-left: 0;
    }

    &:not(:first-child):has(.candidateContactInfoIcon) {
      padding-left: 20px;
    }

    & > a {
      color: $brandPrimary;
    }

    @media only screen and (min-width: $breakingPointSm) {
      border-right: 1px solid $borderColor;
      width: auto;
    }
  }

  .candidateContactInfoItemNoBorder,
  .candidateContactInfoItemNoBorderForce {
    border-right: none;
  }

  .candidateContactInfoIcon {
    fill: $graySecondary;
    margin-left: -6px;
  }

  .candidateContactInfoPlusIcon {
    margin-left: 0;
    margin-right: 10px;
  }

  .candidateContactTalentpoolIcon {
    font-weight: $fontWeightBold;
    color: $brandPrimary;
    margin-right: 5px;
  }

  .candidateContactZendeskIcon {
    fill: $brandPrimary;
    margin-right: 5px;
  }
}

.morePhones {
  padding-left: 10px;
  color: $brandPrimary;

  &:hover {
    text-decoration: underline;
    color: $brandSecondary;
  }
}

.morePhonesPhoneLine {
  color: $eggshellSecondary;
  font-size: pxToRem(14);
}

.linkedinIcon {
  fill: #388abe !important;
}

.infoText {
  padding-left: 8px;
}

.locationCapitalized {
  text-transform: capitalize;
}
