@import '../../../../styles/refactoring/variables';

.jobSettings {
  padding-top: 36px;
  padding-bottom: 36px;
}

.actionIcon {
  fill: $brandPrimary;

  &:not(.actionIconDisabled):hover {
    fill: $brandSecondary;
  }
}

.actionIconDisabled {
  fill: $eggshellSecondary;
}
