@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import './candidateBody.module.scss';

.contentCard {
  @extend .contentCardBase;
  padding: 30px;
  font-size: pxToRem(14);
  line-height: 26px;
}

.content {
  display: grid;
  grid-template-columns: minmax(150px, min-content) auto;
  gap: 20px;
}

.subtitle {
  font-weight: $fontWeightMedium;
}

.valueAndBadge {
  display: flex;
  gap: 10px;
}

.location {
  text-transform: capitalize;
}

.noInfoToShow {
  color: $eggshellSecondary;
  font-size: pxToRem(14);
  font-weight: $fontWeightRegular;
}

.note {
  color: $eggshellSecondary;
  margin-bottom: 0;
}

.documentsAvailable {
  color: $greenPrimary;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    fill: $greenPrimary;
  }
}
