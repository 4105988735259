@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.contentCard {
  background-color: $white;
  box-shadow: 0 0 10px #0000000f;
  border-radius: 12px;
  margin-bottom: 40px;
  padding: 30px;
}
