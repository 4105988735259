@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.calendarWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.hireCandidateDesc {
  padding: 10px 0;
  text-align: center;
  line-height: pxToRem(22);
}
