@import '../../../../../../../styles/refactoring/variables';

.collaboratorTitle {
  margin-bottom: 19px !important;
}

.collaboratorRoleCell {
  position: relative;
}

.collaboratorRemoveIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    fill: $eggshellSecondary;
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: $redPrimary;
    }
  }
}
