@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.page {
  align-items: center;
  margin-top: pxToRem(-64);
}

.logo {
  display: none;
  height: pxToRem(26);
  margin-bottom: 2rem;

  @media only screen and (min-width: $breakingPointMd) {
    display: block;
  }
}

.titleAndInfo {
  margin-bottom: 2rem !important;
}

.submitButton {
  margin-bottom: 2rem;
  margin-top: 0.75rem;
}

.link {
  color: $brandPrimary;
  cursor: pointer;
  font-size: pxToRem(12);
  margin-top: -1rem;

  &:hover {
    color: $brandSecondary;
    text-decoration: none;
  }

  &:active,
  &:visited {
    color: $brandPrimary;
  }
}
