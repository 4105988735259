.analytics {
  padding-top: 40px;
  padding-bottom: 40px;
}

.contentCard {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.cardFullHeight {
  height: 100%;
}
