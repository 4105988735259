@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.placeholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.positionRight {
    flex-direction: row;

    .icon {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }

  &.positionLeft {
    flex-direction: row-reverse;

    .icon {
      margin-bottom: 0;
      margin-left: 25px;
    }
  }

  &.positionTop {
    flex-direction: column-reverse;

    .icon {
      margin-bottom: 0;
      margin-top: 25px;
    }
  }

  .icon {
    align-items: flex-start;
    background-color: $white;
    border-radius: pxToRem(12);
    border: 1px solid $eggshellPrimary;
    box-shadow: 0px 3px 8px #00000014;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding: 15px 15px;
    text-align: center;
  }

  .iconLineShort,
  .iconLineLong {
    background-color: $eggshellPrimary;
    display: inline-block;
  }

  .iconLineShort {
    border-radius: pxToRem(3);
    margin: 10px 0 0;
    height: pxToRem(5);
    width: pxToRem(65);
  }

  .iconLineLong {
    border-radius: pxToRem(2.5);
    height: pxToRem(5);
    width: pxToRem(130);
    margin: 10px 0 0;
  }

  .firstLine {
    margin-top: 0;
  }

  .lastLine {
    margin-top: 22px;
  }

  .text {
    color: $eggshellSecondary;
    font-size: pxToRem(20);
    line-height: pxToRem(26);
  }
}
