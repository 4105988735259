@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import './candidateBody.module.scss';

.contentCard {
  @extend .contentCardBase;
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-top: 30px;
}

.titleText {
  font-size: pxToRem(16);
  font-weight: $fontWeightMedium;
  line-height: pxToRem(22);
}

.buttonContainer {
  display: flex;
  gap: 10px;
}

.titleButtonText {
  font-size: 0.875rem;
  margin-left: 6px;
  display: none;

  @media only screen and (min-width: $breakingPointSm) {
    display: inline-block;
  }
}
.titleButtonIcon {
  height: auto;
  width: 18px;
}

.rciLink {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;

  &:hover {
    text-decoration: none;
  }

  .infoPlusIcon {
    fill: $brandPrimary;
  }
}

.validatedOn {
  color: $graySecondary;
  font-size: pxToRem(11);
  font-weight: $fontWeightMedium;
  line-height: 2;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.alert {
  margin: 10px 0 20px;
}

.additionalInfoSubtitle {
  font-weight: 500;
  margin-bottom: 5px;
}

.text {
  font-size: pxToRem(14);
  margin-bottom: 30px;
  white-space: break-spaces;
}

.nothingToShow {
  @extend .nothingToShowBase;
}

.expander {
  align-items: center;
  border-top: 1px solid $eggshellPrimary;
  color: $brandPrimary;
  cursor: pointer;
  display: flex;
  font-size: pxToRem(14);
  justify-content: center;
  padding: 30px 0;

  svg {
    fill: $brandPrimary;
    margin-right: 10px;
  }

  &:hover {
    color: $brandSecondary;

    svg {
      fill: $brandSecondary;
    }
  }
}
