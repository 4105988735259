@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.pageHeading {
  align-items: center;
  display: flex;
  margin-bottom: 40px !important;
}

.pageHeading {
  & > svg {
    fill: $brandPrimary;
    margin-right: 8px;
  }
}

.additionalInfo {
  font-size: pxToRem(14);
  color: $eggshellSecondary;
  margin: 0;
  padding: 0 0 5px;
}
