@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.jobForm {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

.jobHeading,
.jobSubHeading {
  margin-bottom: 20px !important;
}

.jobSubSection {
  margin-bottom: 12px !important;
}

.jobFileWrapper {
  display: flex;
}

.jobFileInput {
  span {
    width: auto;
  }
}

// START TALENT LANGUAGES
.talentLanguageRow ~ .talentLanguageRow {
  @media only screen and (min-width: $breakingPointMd) {
    label {
      display: none;
    }
  }
}

.talentLanguageSelect,
.talentLevelSelect,
.talentImportanceSelect {
  @media only screen and (min-width: $breakingPointMd) {
    margin: 0 !important;
  }
}

.talentLanguageSelect {
  & input,
  li {
    text-transform: capitalize;
  }
}

.removeLanguageWrapper {
  display: none;

  @media only screen and (min-width: $breakingPointMd) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.removeLanguageIcon {
  cursor: pointer;
  fill: $eggshellSecondary !important;

  &:hover {
    fill: $redPrimary !important;
  }
}

.mobileLanguagesClearSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 26px;
  margin-bottom: 36px;

  @media only screen and (min-width: $breakingPointMd) {
    display: none;
  }
}

.mobileLanguagesClearLine {
  position: absolute;
  height: 100%;
  border-left: 1px solid $eggshellPrimary;
}

.mobileRemoveLanguage {
  background-color: $white;
  display: inline-flex;
  padding: 4px 0;
  z-index: 1;
}

.mobileRemoveLanguageIcon {
  @extend .removeLanguageIcon;
}
// END TALENT LANGUAGES

.fileButtons {
  display: inline-flex;
  align-items: center;
}

.downloadFileIcon {
  cursor: pointer;
  margin-right: 4px;
  fill: $eggshellSecondary !important;

  &:hover {
    fill: $brandPrimary !important;
  }
}

.deleteFileIcon {
  cursor: pointer;
  margin-right: 4px;
  fill: $eggshellSecondary !important;

  &:hover {
    fill: $redPrimary !important;
  }
}

.actionButtons {
  margin-bottom: 16px;
  width: 100%;

  @media only screen and (min-width: $breakingPointLg) {
    margin-bottom: 0;
    width: auto;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.noBenefitSelectedInfo {
  font-size: pxToRem(14);
  color: $eggshellSecondary;
  display: inline-block;
  padding-bottom: 6px;
}

.spacingRemoveTeamLanguage {
  @media only screen and (min-width: $breakingPointMd) {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.spacingRemoveTeamSize {
  margin: 0 !important;
  padding: 0 !important;
}

.aiTitlesSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid $eggshellPrimary;
}

.titleSuggestion {
  cursor: pointer;

  &.selected {
    border-color: $greenSecondary;
  }
}

.check {
  fill: $greenSecondary;
}

.alertTitle {
  font-weight: 500;
  display: block;
}

// KOMBO JOBS
.komboJob {
}

.komboJobLabelSecondary {
  font-size: pxToRem(12);
  color: $eggshellSecondary;
}
