@import '../../../../styles/refactoring/variables';

.jobVersionSection {
  margin: 0 0 38px 0;
}
.horizontalLine {
  border-bottom: 1px solid $eggshellPrimary;
  margin-bottom: 36px;
}

.versionTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.versionTitle {
  display: flex;
  align-items: center;
}

.versionDate {
  color: $eggshellSecondary !important;
  padding: 0 0 16px;
}

.versionDate {
  margin-bottom: 16px !important;
}
