@import '../../../../styles/refactoring/variables';

.adminActions {
  padding-top: 36px;
  padding-bottom: 36px;
}

.contentCardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px !important;

  &.contentCardTitleNoContent {
    margin-bottom: 0 !important;
  }
}

.jobSettingsCard,
.salaryContentCard {
  padding-bottom: 0;
}

.nasInfoContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.removeNasInfoSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 26px;
  margin-bottom: 36px;
}

.removeNasInfoLine {
  position: absolute;
  height: 100%;
  border-left: 1px solid $eggshellPrimary;

  @media only screen and (min-width: $breakingPointMd) {
    display: none;
  }
}

.removeNasInfoButton {
  background-color: $white;
  border: none;
  display: inline-flex;
  padding: 4px 0;
  z-index: 1;
}

.removeNasInfoIcon {
  cursor: pointer;
  fill: $eggshellSecondary;

  &:hover {
    fill: $redPrimary;
  }
}

.addNasInfoButton {
  margin-bottom: 30px;
}

.questionsSwitch {
  display: inline-flex;

  label {
    margin-bottom: 0;
  }
}

.questionsSwitchTooltip {
  font-size: 14px;
  font-weight: 400;
}
.questionsCollapseChevron {
  margin-left: 0.75rem;
}

.questionsExpandedChevron {
  rotate: 180deg;
}

.headingCursor {
  cursor: pointer;
}

.headingCollapsed {
  margin-bottom: 0px !important;
}
