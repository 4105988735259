.insights {
  padding-top: 36px;
  padding-bottom: 36px;
}

.contentCard {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.cardFullHeight {
  height: 100%;
}

.pageHeading {
  margin-bottom: 20px !important;
}
