@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.company {
  padding-top: 36px;
  padding-bottom: 36px;
}

.copyToClipboardWrapper {
  display: flex;
  align-items: center;
  color: $brandPrimary;
  cursor: pointer;
  font-size: pxToRem(14);

  .copyToClipboard {
    display: inline-flex;

    & > svg {
      fill: $brandPrimary;
      margin-right: 4px;
    }
  }
}

.rowWithPreview {
  display: flex;
}

.previewSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border: 1px solid $eggshellPrimary;
  border-radius: 8px;

  & > img {
    width: 24px;
  }

  & > span {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }
}

.previewInputSection {
  flex: 1;
  margin-left: 14px;
}

.removeIcon {
  cursor: pointer;
}

.formInputAcuityLinks {
  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.messageTemplates {
  margin-bottom: 30px;
}

.integrations {
}

.integrationLabel {
  font-weight: $fontWeightSemibold;
}

.integrationLabelCapitalized {
  text-transform: capitalize;
}

.integrationText {
  color: $eggshellSecondary;
  font-size: pxToRem(14);
}
