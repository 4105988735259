@import '../../../../../../styles/refactoring/variables';

.infoBox {
  padding: 25px;
}

.infoBoxValue {
  font-size: 0.875rem;
  line-height: 1;
  margin-bottom: 25px;
}

.infoBoxValuePrimary {
  font-size: 2rem;
  color: $orangePrimary;
  font-weight: $fontWeightMedium;
}

.infoBoxLabel {
  font-size: 0.875rem;
  font-weight: $fontWeightMedium;
}
