.contentCardBase {
  background-color: $white;
  border-radius: pxToRem(16);
  box-shadow: 0px 0px 10px #00000017;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.nothingToShowBase {
  color: $eggshellSecondary;
  font-size: pxToRem(14);
  margin-bottom: 30px;
}
