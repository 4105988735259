.companyInsights {
  padding-top: 36px;
  padding-bottom: 36px;
}

.cardFullHeight {
  height: 100%;
}

.pageHeading {
  margin-bottom: 20px !important;
}
