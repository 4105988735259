@import '../../../../../../styles/refactoring/variables';

.actionDropdown {
  z-index: 0;

  .settingsIcon {
    fill: $brandPrimary;

    &:hover {
      fill: $brandSecondary;
    }
  }

  .settingsIconActive {
    fill: $brandSecondary;
  }
}
