@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.purchases {
  padding-top: 32px;
  padding-bottom: 52px;

  @media only screen and (min-width: $breakingPointLg) {
    padding-bottom: 32px;
  }
}
