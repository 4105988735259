@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.candidateFilter {
  align-items: stretch !important;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $eggshellPrimary;
  display: flex;
  height: 38px;
  margin-bottom: 30px;
  overflow: hidden;
}

.candidateFilterTitle {
  align-items: center;
  color: $eggshellSecondary;
  display: flex;
  flex: 0 0 auto;
  font-size: pxToRem(14);
  padding: 0 15px;
  text-transform: uppercase;
}

.candidateFilterItemWrapper {
  align-items: stretch;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  padding: 0 15px;
  position: relative;
}

.candidateFilterItem {
  align-items: center;
  cursor: pointer;
  display: flex;

  &:hover {
    .candidateFilterItemText,
    .candidateFilterItemCount {
      color: $brandPrimary;
    }
  }
}

.candidateFilterItemActive {
  .candidateFilterItemText,
  .candidateFilterItemCount {
    color: $brandPrimary;
  }
}

.candidateFilterItemEmpty {
  .candidateFilterItemText,
  .candidateFilterItemCount {
    color: $eggshellSecondary;
  }
}

.candidateFilterItemText,
.candidateFilterItemCount {
  font-size: pxToRem(12);
}

.candidateFilterItemCount {
  color: $eggshellSecondary;
  padding-left: 6px;
}

.candidateFilterArrowLeft,
.candidateFilterArrowRight {
  align-items: center;
  background-color: $eggshellLight;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 38px;
}

.candidateFilterArrowMaxLeft,
.candidateFilterArrowMaxRight {
  cursor: not-allowed;

  svg {
    fill: $eggshellSecondary;
  }
}
