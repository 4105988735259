@import '../../../../../../styles/refactoring/variables';

.pickerBtn {
  position: relative;
}

.pickerWrapper {
  position: absolute;
  top: -20px;
  right: -2px;
  z-index: 10;
}

.closeIcon {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 10;
  background-color: $white;
  border-radius: 50%;
  cursor: pointer;

  fill: $redPrimary !important;
}
