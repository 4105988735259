@import '../../../../../../styles/refactoring/utils';

.placeholder {
  margin: 40px 0;
  padding: 46px 0;
  position: relative;
}

.placeholderText {
  font-size: pxToRem(16);
  line-height: pxToRem(22);
  white-space: pre-line;
}
