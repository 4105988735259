@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.creditsHistoryCard {
  padding: 30px;
}

.creditHistoryTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px !important;
}

.totalCreditsInfo {
  font-size: pxToRem(14);
  font-weight: $fontWeightRegular;

  & > span {
    font-weight: $fontWeightMedium;
  }
}

.revertedCreditRow {
  background-color: $brandLight !important;
}

.fixedCell {
  white-space: pre-wrap;
  width: pxToRem(180);
  line-height: pxToRem(22);
}

.jobTitle {
  font-weight: $fontWeightMedium;
}
