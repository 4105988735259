@import '../../../../../../styles/refactoring/variables';

.salaryTitle {
  padding: 25px;
}

.salaryContainer {
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px;
}

// Statistic
.salaryStat {
  display: flex;
  border-bottom: 1px solid $eggshellPrimary;
  padding-bottom: 25px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.salaryStatItem {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.salaryStatValue {
  font-size: 1.5rem;
  font-weight: $fontWeightMedium;
}

.salaryStatLabel,
.salaryStatLabelSecondary {
  font-size: 0.75rem;
}

.salaryStatLabelSecondary {
  color: $eggshellSecondary;
}

// Max And Min
.minMaxAskedContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.minMaxAskedValue {
  font-size: 0.75rem;
}

// Range Chart
.rangeChartContainer {
  position: relative;
  margin: 35px 0 50px;
}

.rangeChartAsked {
  background-color: $eggshellPrimary;
  height: 6px;
  width: 100%;
  border-radius: 3px;
}

.rangeChartAskedAverage {
  font-size: 0.75rem;
  position: absolute;
  top: -37px;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 7px 0 7px;
    border-color: #a6a39b transparent transparent transparent;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.rangeChartAskedAverageValue {
  font-weight: $fontWeightMedium;
}

.rangeChartMinMax {
  display: flex;
  justify-content: space-between;
  background-color: #66c1b0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 3px;
}

.rangeChartMinValue,
.rangeChartMaxValue {
  font-size: 0.75rem;
  margin-top: 10px;
  color: #66c1b0;
}
