@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.modalBody {
  .modalBodyWarning,
  .modalBodyInfo {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
    text-align: center;
  }

  .modalBodyWarning {
    color: $orangePrimary;
    margin-top: 10px;
  }

  .modalBodyInfo {
    margin-bottom: 78px;
    white-space: pre-line;
  }
}
