@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import '../../../../../../styles/refactoring/mixins';

.candidate {
  border-radius: pxToRem(12);
  border: 1px solid $greenPrimary;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: pxToRem(180);
  justify-content: center;
  margin: 0 15px;
  padding: 0 16px;
  position: relative;
  width: pxToRem(200);

  &:hover {
    box-shadow: 0px 3px 8px rgba($greenPrimary, 0.26);
  }
}

.candidate.notPaidCard {
  padding: 0;
  border: 1px solid transparent;
}

.candidateImageWrapper {
  display: flex;
}

.candidateImageContainer {
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 0 auto 15px auto;
}

.candidateImage {
  border-radius: 50%;
  height: pxToRem(61);
  width: pxToRem(61);
}

.candidateName {
  @include ellipsis;
  font-size: pxToRem(14);
  font-weight: $fontWeightMedium;
  line-height: pxToRem(26);
  text-align: center;
}

.candidatePosition,
.candidateExperience {
  @include ellipsis;
  color: $eggshellSecondary;
  font-size: pxToRem(12);
  line-height: pxToRem(20);
  text-align: center;
}

.candidatePosition {
}

.candidateExperience {
}

.notPaidCard {
  position: relative;
  cursor: default;
}

.notPaidContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($grayPrimary, 0.9);
  color: $white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
  backdrop-filter: blur(3px);
}

.notPaidTitle {
  font-size: pxToRem(14);
  text-align: center;
  font-weight: $fontWeightMedium;
}

.notPaidDesc {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: pxToRem(12);
  text-align: center;
}
