@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.postLoginLayout {
  background-color: $eggshellLight;
  box-sizing: border-box;
  margin-top: 5.5rem;
  min-height: calc(100vh - 5.5rem);
}

.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 60;
}

.content {
  min-height: calc(100vh - 5.5rem);
}
