@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.header {
  background-color: #213057;
  box-shadow: 0px 5px 10px #0000001a;
  color: $white;
  display: flex;
}

.headerLeft,
.headerRight {
  flex: 0;
  max-width: 5.5rem;
  box-sizing: border-box;
  min-width: pxToRem(54);

  @media only screen and (min-width: $breakingPointSm) {
    flex: 1;
  }

  @media only screen and (min-width: $breakingPointLg) {
    max-width: 13.375rem;
  }
}

// Left
.headerLeft {
  align-items: center;
  border-right: 1px solid rgba($brandLight, 0.3);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media only screen and (min-width: $breakingPointLg) {
    padding: 35px 0;
  }
}

.logoDesktop {
  display: none;

  @media only screen and (min-width: $breakingPointLg) {
    display: block;
    height: 1.125rem;
    width: auto;
  }
}

.logoMobile {
  display: block;
  height: 1.5rem;
  width: auto;

  @media only screen and (min-width: $breakingPointLg) {
    display: none;
  }
}

// Middle
.headerMiddle {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.middleLeft {
  align-items: center;
  display: flex;
  padding-left: 16px;

  @media only screen and (min-width: $breakingPointSm) {
    padding-left: 40px;
  }
}

.headerLink {
  align-items: center;
  border-radius: 0.375rem;
  color: $white;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1;
  margin: 0 8px;
  text-decoration: none;

  &:hover {
    color: $brandPrimary;
    text-decoration: none;

    .headerLinkIcon {
      fill: $brandPrimary !important;
    }
  }

  @media only screen and (min-width: $breakingPointSm) {
    margin: 0 20px;
  }
}

.headerLinkActive {
  background-color: $white;
  color: $grayPrimary;
  margin: 0;
  padding: 8px;

  .headerLinkIcon {
    fill: $grayPrimary !important;
  }

  @media only screen and (min-width: $breakingPointSm) {
    padding: 8px 20px;
  }
}

.headerLinkIcon {
  fill: $white !important;

  @media only screen and (min-width: $breakingPointLg) {
    margin-right: 4px;
  }
}

.headerLinkLabel {
  display: none;

  @media only screen and (min-width: $breakingPointLg) {
    display: inline;
  }
}

.headerLinkSpacer {
  display: none;

  @media only screen and (min-width: $breakingPointSm) {
    display: inline-block;
    height: 1px;
    width: 1.25rem;
  }
}

.middleRight {
  display: flex;
}

.creditDesktop,
.loggedInInfoDesktop {
  display: none;

  @media only screen and (min-width: $breakingPointLg) {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
  }

  .creditLabel,
  .loggedInInfoLabel {
    font-size: 0.75rem;
  }

  .creditValue,
  .loggedInInfoValue {
    font-size: 0.875rem;
  }
}

.creditMobile {
  @media only screen and (min-width: $breakingPointLg) {
    display: none;
  }

  padding-right: 16px;
  font-size: pxToRem(14);

  @media only screen and (min-width: $breakingPointSm) {
    font-size: pxToRem(16);
    padding-right: 28px;
  }

  .creditMobileLabel {
    color: $eggshellSecondary;
    text-transform: capitalize;
  }
}

.creditLabel,
.loggedInInfoLabel {
  color: $eggshellSecondary;
}

.loggedInInfoValue {
  display: inline-flex;
}

.loggedInInfoIcon {
  fill: $white;
  margin: -2px 0 0 8px;
}

.creditLabel {
  text-transform: capitalize;
}

// Right
.headerRight {
  align-items: center;
  border-left: 1px solid rgba($brandLight, 0.3);
  display: flex;
  justify-content: center;
}

.headerRightMobileActive {
  background-color: rgba($brandLight, 0.3);

  @media only screen and (min-width: $breakingPointLg) {
    background-color: inherit;
  }
}

.languageDropdownDesktop,
.profileDropdownDesktop {
  display: none;

  @media only screen and (min-width: $breakingPointLg) {
    display: inline;
    margin: 0 20px;
  }
}

.viewMoreMobile {
  cursor: pointer;
  padding: 8px;
  fill: $white !important;
  box-sizing: border-box;

  @media only screen and (min-width: $breakingPointLg) {
    display: none;
  }
}
