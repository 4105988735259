@import '../../../../../../styles/refactoring/variables';

.translationContainer {
}

.translations {
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.container {
  position: relative;
  width: 100%;
}

.textArea {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  width: 100%;

  &.hideTextarea {
    display: none;
  }
}

.translationPlaceholder {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $eggshellPrimary;
  display: none;
  font-size: 0.875rem;
  line-height: 1.625rem;

  padding: 8px 12px 8px 35px;
  min-height: 44px;

  &.showPlaceholder {
    display: block;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.flag {
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: unset;
  top: unset;
  width: 20px;

  &.showPlaceholder {
    right: unset;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
