@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import './candidateBody.module.scss';

.contentCard {
  @extend .contentCardBase;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media only screen and (min-width: $breakingPointSm) {
    flex-direction: row;
  }
}

.sectionTitle {
  font-size: pxToRem(16);
  font-weight: $fontWeightMedium;
  line-height: pxToRem(22);
  margin: 30px 0;
}

.sectionLabel {
  font-size: pxToRem(14);
  font-weight: $fontWeightMedium;
  line-height: pxToRem(18);
  margin-bottom: 30px;
  padding: 0;
  width: 100%;

  @media only screen and (min-width: $breakingPointSm) {
    padding: 0 8px 0 0;
    width: 20%;
  }
}

.sectionContent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.sectionLabel + .sectionContent {
  padding: 0;
  width: 100%;

  @media only screen and (min-width: $breakingPointSm) {
    padding: 0 0 0 8px;
    width: 80%;
  }
}

.sectionLineBreak {
  background-color: $eggshellPrimary;
  height: 1px;
  width: 100%;
  margin-bottom: 30px;
}

.nothingToShow {
  @extend .nothingToShowBase;
}

// Pillows
.contentItemLabelValueWrapper,
.contentItemPillowWrapper {
  margin-bottom: 30px;
}

.contentItemPillowWrapper {
  padding: 0 8px;
  width: 50%;

  @media only screen and (max-width: $breakingPointLg) {
    &:nth-child(2n + 1) {
      padding-left: 0;
    }

    &:nth-child(2n) {
      padding-right: 0;
    }
  }

  @media only screen and (min-width: $breakingPointLg) and (max-width: $breakingPointXl) {
    width: 33.33%;

    &:nth-child(3n + 1) {
      padding-left: 0;
    }

    &:nth-child(3n) {
      padding-right: 0;
    }
  }

  @media only screen and (min-width: $breakingPointXl) {
    width: 25%;

    &:nth-child(4n + 1) {
      padding-left: 0;
    }

    &:nth-child(4n) {
      padding-right: 0;
    }
  }
}

.contentPillowItem {
  border-radius: 99px;
  border: 1px solid $eggshellPrimary;
  font-size: pxToRem(14);
  line-height: pxToRem(22);
  overflow: hidden;
  padding: 3px 16px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

// LabelValue
.contentItemLabelValueWrapper {
}

.contentItemLabelValue {
  line-height: pxToRem(22);
  font-size: pxToRem(14);
  font-weight: $fontWeightMedium;
}

.contentItemLabelValueTitle {
  font-weight: $fontWeightMedium;
  padding-bottom: 10px;
}

.contentItemLabelValueSubtitle {
  color: $eggshellSecondary;
  padding-bottom: 10px;
  font-weight: $fontWeightRegular;
}

.contentItemLabelValueSubtitleNoPadding {
  padding-bottom: 0;
}

.contentItemLabelValueDescription {
  font-weight: $fontWeightRegular;
}

.capitalize {
  text-transform: capitalize;
}
