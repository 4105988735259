@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.header {
  background-color: $white;
  display: flex;
  padding: 20px 40px;
  min-height: pxToRem(78);
}

.headerLeft,
.headerRight {
  flex: 1;
  display: flex;
  align-items: center;
}

// Left
.headerLeft {
}

.logo {
  height: pxToRem(18);
  width: auto;

  @media only screen and (min-width: $breakingPointMd) {
    display: none;
  }
}

// Right
.loginText {
  color: $eggshellSecondary !important;
  display: none !important;

  @media only screen and (min-width: $breakingPointMd) {
    display: inline-block !important;
  }
}

.loginButton {
  margin: 0 1rem 0 0.5rem;
  text-transform: capitalize;
  border-color: $eggshellSecondary !important;
  color: $eggshellSecondary !important;

  &:hover,
  &:active {
    border-color: darken($eggshellSecondary, 0.2) !important;
    color: darken($eggshellSecondary, 0.2) !important;
  }
}

.headerRight {
  justify-content: flex-end;
}
