@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';
@import '../../../../../../styles/refactoring/mixins';

.jobCard {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0px 0px 10px #00000017;
  overflow: hidden;
}

.header {
  align-items: flex-start;
  background-color: $eggshellLight;
  display: flex;
  justify-content: space-between;
  padding: 17px 24px;
}

.actions {
  display: flex;
  margin-top: -4px;
  flex: 1;
  justify-content: space-between;
}

.actionGroup {
  display: flex;

  &:first-child {
    flex: 1;
  }
}

.actionItem {
  &:not(:last-child) {
    margin-right: 16px;
  }

  a {
    color: $brandPrimary;
    font-size: pxToRem(13);

    &:hover {
      color: $brandSecondary;
    }
  }
}

.actionItemIcon {
  fill: $brandPrimary;
  width: 22px;
  margin-top: -2px;
  margin-right: 2px;
}

.body {
  border-bottom: 1px solid $eggshellPrimary;
  margin: 0 24px;
  padding: 17px 0;
}

.info {
  padding: 10px 0;
}

.dateAndStatus,
.atsStatus {
  display: flex;
  align-items: center;
  padding: 0;
}

.date,
.status,
.atsStatus {
  flex: 1;
}

.dateLabel,
.statusLabel,
.atsLabel {
  color: $eggshellSecondary;
  font-size: pxToRem(12);
}

.date,
.status,
.ats {
  font-size: pxToRem(12);
}

.oneLandingPageIcon {
  margin-top: -4px;
  color: $eggshellSecondary;

  &:hover {
    color: $grayPrimary;
  }
}

.title {
  @include ellipsis;
  font-weight: $fontWeightSemibold;
  margin: 8px 0 0;
}

.titleLink {
  color: $grayPrimary;

  &:hover {
    color: $brandPrimary;
    text-decoration: none;
  }
}

.replacementName {
  font-size: pxToRem(12);
  height: 18px;
  @include ellipsis;
}

.replacementNameLabel {
  font-weight: $fontWeightSemibold;
}

.location {
  align-items: center;
  color: $eggshellSecondary;
  display: flex;
  font-size: pxToRem(12);
  font-style: italic;

  svg {
    margin-left: -6px;
    fill: $eggshellSecondary;
  }
}

.footer {
  padding: 17px 24px 30px;
}

.actionButtons {
  display: flex;
  flex-wrap: wrap;
}

.buttonItem {
  box-sizing: border-box;
  margin-top: 20px;
  width: 100%;

  @media only screen and (min-width: $breakingPointSm) {
    width: 50%;

    &:nth-child(even) {
      padding-left: 10px;
    }

    &:nth-child(odd) {
      padding-right: 10px;
    }

    button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.candidates {
  align-items: center;
  display: flex;
}

.candidatesLabel {
  font-size: pxToRem(14);
}

.candidatesCount {
  align-items: stretch;
  border-radius: 99px;
  border: 1px solid $eggshellSecondary;
  display: flex;
  margin-left: 14px;
}

.totalCount,
.candidatesInContact {
  align-items: center;
  color: $eggshellSecondary;
  display: inline-flex;
  font-size: pxToRem(12);
  line-height: 1.25;
  padding: 3px 14px;
}

.totalCount {
  border-right: 1px solid $eggshellSecondary;
}
