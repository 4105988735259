@import '../../../../../../styles/refactoring/variables';

.questionsInputWrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.questionsInput {
  flex: 1;
}

.questionsRemove {
  margin-top: -14px;
  cursor: pointer;

  svg {
    fill: $eggshellSecondary;
  }

  &:hover {
    svg {
      fill: $redPrimary;
    }
  }
}

.dragHandler {
  margin-top: -12px;
  padding: 8px 5px;

  &:hover {
    background-color: $eggshellLight;
    border-radius: 5px;
    cursor: grab;
  }
}

.dragHandlerIcon {
  fill: $eggshellSecondary;
}
