@import '../../../../../../styles/refactoring/variables';

.accordionItemError {
  border-color: $redPrimary !important;
}

.questionsHeader {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.headerDragHandler {
  fill: lighten($grayPrimary, 50) !important;
  margin-right: 0.75rem;
  cursor: grab;
}

.headerTitleWrapper {
  position: relative;
  flex: 1;
}

.headerTitle {
  margin-bottom: 0;
}

.headerTitleError {
  position: absolute;
  color: $redPrimary;
  font-size: 0.675rem;
  left: 0;
  bottom: -0.675rem;
  margin: 0;
}

.headerDeleteQuestion {
  fill: $eggshellSecondary !important;
  margin-left: auto;

  &:hover {
    fill: $redPrimary !important;
  }
}
