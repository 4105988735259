@import '../../../../../../../styles/refactoring/variables';

.addNewViewerForm {
  margin-top: 27px;
  margin-bottom: 21px;

  @media only screen and (min-width: $breakingPointMd) {
    margin-bottom: 0;
  }

  .formItems {
    margin-bottom: 5px;
  }

  .submitButton {
    min-width: 100%;

    @media only screen and (min-width: $breakingPointMd) {
      margin-top: 26px;
    }
  }
}

.sendCollaboratorStatus {
  margin-bottom: 47px;
}
