@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.multipleChoiceItem {
  position: relative;
}

.multipleChoiceItemDelete {
  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: calc(100% - 4.25rem);
    background-color: $eggshellSecondary;
    position: absolute;
    right: pxToRem(20);
    top: calc(50% - 0.125rem);
    transform: translateY(calc(-50% - 0.125rem));
  }

  @media only screen and (min-width: $breakingPointMd) {
    &::before {
      display: none;
    }
  }
}

.multipleChoiceItem ~ .multipleChoiceItem {
  @media only screen and (min-width: $breakingPointMd) {
    label {
      display: none !important;
    }

    .deleteAnswer {
      margin-top: 4px;
    }
  }
}

.multipleChoiceAnswer,
.multipleChoiceAnswerType {
  margin-bottom: 36px;

  @media only screen and (min-width: $breakingPointMd) {
    margin-bottom: 16px;
  }
}

.deleteAnswerWrapper {
  position: absolute;
  right: 0;
  top: 48%;
  transform: translateY(-52%);
  background-color: $white;
  padding: 4px;

  @media only screen and (min-width: $breakingPointMd) {
    top: 0;
    transform: translateY(0);
  }
}

.deleteAnswer {
  display: inline-block;
  cursor: pointer;
  margin-top: 0;

  @media only screen and (min-width: $breakingPointMd) {
    position: absolute;
    right: pxToRem(11);
  }

  svg {
    fill: $eggshellSecondary !important;
    height: pxToRem(34);
    width: pxToRem(34);

    &:hover {
      fill: $redPrimary !important;
    }
  }

  @media only screen and (min-width: $breakingPointMd) {
    margin-top: 30px;
    margin-bottom: 0;

    svg {
      fill: $eggshellSecondary !important;
      height: unset;
      width: unset;
    }
  }
}

.addAnswerBtnWrapper {
  padding-top: 16px;
  margin-top: 8px;
}

.addAnswerBtn {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  color: $brandPrimary;
}

.addAnswerBtnIcon {
  margin-right: 4px;
  fill: $brandPrimary !important;
}
