@import '../../../styles/refactoring/variables';
@import '../../../styles/refactoring/utils';

.preLoginLayout {
  background-color: $white;
  margin-top: pxToRem(78);
  min-height: calc(100vh - 78px);
}

.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 50;
}

.content {
  min-height: calc(100vh - 78px);
  display: flex;
}
