@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.dashboard {
  padding-top: 32px;
  padding-bottom: 52px;

  @media only screen and (min-width: $breakingPointLg) {
    padding-bottom: 32px;
  }
}

.header {
  align-items: flex-start;
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;

  @media only screen and (min-width: $breakingPointSm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
  }

  .totalCount {
    background-color: $eggshellPrimary;
    border-radius: pxToRem(12);
    display: inline-block;
    font-size: pxToRem(12);
    font-weight: $fontWeightRegular;
    margin-left: 10px;
    padding: 5px 12px;
  }

  .addNewJobBtn {
    width: 100%;
    margin: 23px 0 10px;

    @media only screen and (min-width: $breakingPointSm) {
      width: auto;
      margin: 0;
    }
  }
}

.paginationWrapper {
  margin-top: 30px;
  z-index: 1;
}

.pagination {
  position: fixed;
  left: 0;
  bottom: 0;
  border-radius: 0 !important;

  @media only screen and (min-width: $breakingPointLg) {
    position: relative;
    left: unset;
    bottom: unset;
    border-radius: 0.5rem !important;
  }
}

.placeholderText {
  font-size: pxToRem(16);
  line-height: pxToRem(22);
  white-space: pre-line;
}
