@import '../../../../../../styles/refactoring/variables';

.container {
  background-color: $eggshellLight;
  border-radius: 8px;
  border: 1px solid $eggshellPrimary;
  position: relative;
  width: 100%;
}

.picturesContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 8px 0;
}

.pictureWrapper {
  margin-bottom: 16px;
  padding: 0 8px;
  position: relative;
  width: 20%;
}

.picture {
  width: 100%;
}

.pictureRemove {
  background-color: $white;
  border-radius: 50%;
  cursor: pointer;
  fill: $redPrimary !important;
  position: absolute;
  right: 15px;
  top: 5px;
}

.placeholderContainer {
  padding: 15px 30px;
  text-align: center;
}

.placeholderIcon {
  color: $eggshellSecondary;
  margin-bottom: 20px;
  width: 35px;
}

.placeholderText {
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
}

.error {
  bottom: -1.325rem;
  color: $redPrimary;
  font-size: 0.75rem;
  position: absolute;
}
