@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.modalBody {
  .modalBodyAttention,
  .modalBodyInfo {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
    text-align: center;
  }

  .modalBodyAttention {
    color: $orangePrimary;
    margin-top: 10px;
  }

  .modalBodyInfo {
    margin-bottom: 78px;
  }
}
