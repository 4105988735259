@import '../../../../styles/refactoring/variables';

.headerDropdown {
  position: relative;
  z-index: 1;
  display: inline-flex;
}

.dropdownTrigger {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 1;
}

.dropdownTriggerAvatar {
  border-radius: 50%;
  border: 1px solid $eggshellPrimary;
  display: inline-block;
  height: 1.375rem;
  overflow: hidden;
  position: relative;
  width: 1.375rem;

  svg,
  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.dropdownTriggerIcon {
  margin-left: 4px;
  fill: $white !important;
}

.dropdownTriggerIconSecondary {
  fill: $brandPrimary !important;
}

.menu {
  background-color: $grayPrimary;
  border-radius: 0px 0px 0.625rem 0.625rem;
  box-shadow: 0px 0.375rem 0.625rem #000033;
  display: none;
  padding: 64px 40px 0;
  position: absolute;
  right: -1.25rem;
  top: -1.25rem;
}

.menuSecondary {
  background-color: $white;
  box-shadow: 0px 0.375rem 0.625rem #cccccc;
}

.open {
  display: inline-block;
}

.menuItem {
  align-items: center;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  padding: 18px 48px 18px 0;
  position: relative;
  text-decoration: none !important;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($brandLight, 0.3);
  }

  &:hover {
    color: $brandPrimary;

    .menuItemFlag {
      border: 1px solid $brandPrimary;
    }
  }
}

.menuItemSecondary {
  color: $brandPrimary;
}

.menuItemNoPadding {
  padding-right: 0;
}

.menuItemFlag {
  display: flex;
  border-radius: 50%;
  border: 1px solid $eggshellPrimary;
  height: 1.375rem;
  margin-right: 10px;
  width: 1.375rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.menuItemChecked {
  fill: $brandSecondary !important;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
}
