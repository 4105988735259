@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.drawer {
  top: pxToRem(88) !important;
}

.drawerContent {
  background-color: $grayPrimary;
  box-shadow: -5px 0px 10px #0000002f;
  color: $white;
  height: 100%;
  overflow-y: auto;
  padding: 40px;
  width: 100%;
}

.drawerTopPart {
  border-bottom: 1px solid #55577c;
  display: flex;
  padding-bottom: 40px;
  width: 100%;

  & img {
    width: pxToRem(42);
    height: pxToRem(42);
    border-radius: 50%;
  }
}

.drawerUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-left: 12px;

  .userLabel,
  .companyLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25rem;
  }

  .userLabel {
    font-size: pxToRem(18);
    font-weight: 500;
  }

  .companyLabel {
    font-size: pxToRem(16);
    color: $brandLight;
    font-weight: 400;
  }
}

.drawerMiddlePart {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  border-bottom: 1px solid #55577c;
}

.drawerBottomPart {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}

.menuItem {
  align-items: center;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: pxToRem(18);
  font-weight: 400;
  line-height: 1rem;
  padding: 20px 0;
  text-decoration: none !important;
  text-transform: capitalize;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    color: $brandPrimary;
  }
}

.languageMenuItem {
  @extend .menuItem;
}

.menuItemFlag {
  border-radius: 50%;
  border: 1px solid $eggshellPrimary;
  height: pxToRem(30);
  margin-right: 10px;
  width: pxToRem(30);

  svg {
    width: 100%;
    height: 100%;
  }
}

.menuItemChecked {
  color: $brandPrimary;

  & span:first-child {
    border: 1px solid #3789ca;
  }

  .menuItemCheckedIcon {
    padding-left: 8px;
    svg {
      fill: $brandPrimary !important;
    }
  }
}

.languageLabel {
  text-transform: capitalize;
  color: $eggshellLight;
  font-size: pxToRem(18);
  padding-bottom: 12px;
  font-weight: 500;
}
