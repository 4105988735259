@import '../../../styles/refactoring/variables';

.fieldInfo {
  background-color: $orangeLight;
  border: 1px solid #f7eac8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: -2rem;
  margin-bottom: 0;
  padding: 0.25rem 0.75rem;

  svg {
    margin-right: 0.75rem;
  }
}
