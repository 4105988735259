.addCreditsCard {
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 16px;
}

.addCreditsCardTitle {
  margin-bottom: 20px !important;
}

