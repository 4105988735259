@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.modalBody {
  .modalBodyText {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
    margin-top: 10px;
    margin-bottom: 78px;
    text-align: center;
  }
}
