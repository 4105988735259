@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.userProfile {
  padding-top: 36px;
  padding-bottom: 36px;

  .card {
    padding: 30px;
    margin-bottom: 40px;
  }

  .formRadioButton {
    margin: 0;
    padding: 0;
  }
}

.infoPlusLabel {
  display: inline-flex;
  align-items: center;
  font-size: pxToRem(14);
  line-height: pxToRem(22);
  color: $grayPrimary;
  font-weight: $fontWeightSemibold;
  margin-bottom: 24px;
}

.infoPlusIcon {
  fill: $grayPrimary;
  margin: 0 6px 0 -2px;
}
