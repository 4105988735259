.filter {
  margin-bottom: 30px;
}

.filterLabel {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  text-transform: uppercase;
}
