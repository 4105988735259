@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.rejectCandidateModal {
  @media only screen and (min-width: $breakingPointLg) {
    width: 950px !important;
  }

  @media only screen and (min-width: $breakingPointXl) {
    width: 1170px !important;
  }

  .rejectCandidateModalBody {
    padding: 50px 0 60px;

    .info {
      font-size: pxToRem(14);
      line-height: pxToRem(22);
    }

    .infoParagraphSection {
      padding-bottom: 10px;
    }

    .infoWarning {
      border-top: 1px solid $eggshellPrimary;
      font-weight: $fontWeightMedium;
      margin-top: 12px;
      padding-top: 22px;
    }
  }
}

.rejectionReasonWrapper {
  padding-top: 3px;
}

.rejectionReasonItem {
  &:first-child {
    max-width: 410px;
    margin-bottom: 24px;
  }
}
