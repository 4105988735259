@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.modal {
  @media only screen and (min-width: $breakingPointLg) {
    width: 950px !important;
  }

  @media only screen and (min-width: $breakingPointXl) {
    width: 1170px !important;
  }

  .modalBody {
    margin: 55px 0;
  }
}
