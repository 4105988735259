@import '../../../../styles/refactoring/variables';
@import '../../../../styles/refactoring/utils';

.createVersionModal {
  @media only screen and (min-width: $breakingPointLg) {
    width: 950px !important;
  }

  @media only screen and (min-width: $breakingPointXl) {
    width: 1170px !important;
  }
}

.createVersionModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .createVersionDescription {
    font-size: pxToRem(14);
    padding-bottom: 56px;
    text-align: center;
    white-space: pre-line;
    line-height: pxToRem(22);
  }
}

.createVersionTextarea {
  width: 100%;
  display: block;
}

.createModalFooter {
  padding-top: 60px;
}
