@import '../../../../../../styles/refactoring/variables';
@import '../../../../../../styles/refactoring/utils';

.headerTitle {
  font-weight: $fontWeightMedium;
}

.totalCandidates {
  border-radius: pxToRem(99);
  border: 1px solid $eggshellPrimary;
  color: $eggshellSecondary;
  display: inline-flex;
  font-size: pxToRem(12);
  font-weight: $fontWeightMedium;
  height: pxToRem(24);
  justify-content: center;
  margin-left: 10px;
  padding: 2px 10px;
  width: pxToRem(34);
}

.totalCandidatesActive {
  color: $brandPrimary;
}
