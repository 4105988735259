@import '../../../../../../styles/refactoring/variables';

.title {
  margin-bottom: 27px;
}

.confirmed {
  color: $redPrimary;
}

.actionIcon {
  fill: $eggshellSecondary !important;

  &:hover {
    fill: $brandPrimary !important;
  }
}

.actionIconActive {
  fill: $brandPrimary !important;
}
