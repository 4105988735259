.inviteExistingForm {
  margin-top: 27px;
  margin-bottom: 21px;

  .submitButton {
    margin-top: 26px;
    min-width: 100%;
  }
}

.sendCollaboratorStatus {
  margin-bottom: 47px;
}
