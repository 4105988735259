@import '../../../../../../styles/refactoring/variables';

.row:not(:last-child) {
  margin-bottom: 24px;

  border-bottom: 1px solid $eggshellPrimary;
}

.question {
  font-size: 14px;
}

.answers {
  font-size: 14px;
  padding: 0 0 0 16px;
  margin: 0 0 24px 0;
}

.answer {
  margin: 0 0 4px 0;
}

.select {
  margin-bottom: 1.125rem;
}
