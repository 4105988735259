@import '../../../../../../styles/refactoring/utils';

.modalBody {
  .bodyContainer {
    max-width: 370px;
    margin: 55px auto 60px;
  }

  .bodyText {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
    margin-bottom: 6px;
  }
}
